@import "./template.scss";
@import "mixin";

* {
  margin: 0;
  padding: 0;
  font-family: "jost";
  box-sizing: border-box;
}
body {
  max-width: 100%;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

button {
  outline: none;
  border: none;
  text-align: center;
  cursor: pointer;
}

a {
  color: black;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

.error-text {
  color: var(--red);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background-color: #00000085 !important;
}

.hidden {
  display: none;
}

@include sm {
  html {
    font-size: 3.2vw; // 10px on 320px
  }
}

@import url("./home/home.scss");
@import url("./common/common.scss");

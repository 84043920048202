@import "../mixin";

.header {
  background-color: var(--gray-color);
  width: 100vw;
  max-width: 100%;

  &__content {
    margin: 0 auto;
    max-width: var(--home-width-content);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sing-up__button {
      background-color: var(--red);
      &:hover {
        background-color: var(--red-hover);
      }
    }
  }

  &__controls {
    display: flex;
    gap: 24px;
    height: fit-content;
  }

  &__text {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    padding: 30px 0;
    &__name {
      line-height: 28px;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 0.64px;
      color: var(--red);
    }

    &__info {
      color: var(--gray-text);
      font-size: 16px;
      letter-spacing: 0.32px;
      &__name {
        color: var(--red);
      }
    }
  }

  // @media (max-width: 1130px) {
  //   padding: 0 24px;
  // }

  // @media (max-width: 768px) {
  //   padding: 0 24px;
  // }

  @include lg {
    padding: 0 20px;

    &__controls {
      padding-top: 16px;

      .custom-button {
        padding: 12px 24px;
      }

      @media (max-width: 870px) {
        gap: 12px;
        .custom-button {
          img {
            display: none;
          }
        }
      }

      .chose-lang {
        margin-left: auto;
      }
    }
  }

  @include lsm {
    padding: 0 48px;

    &__content {
      flex-direction: column-reverse;
    }

    &__controls {
      width: 100%;
      padding-top: 16px;

      gap: 12px;

      button {
        font-size: 18px;
        padding: 12px 24px;
      }

      .chose-lang {
        margin-left: auto;

        .lang-item.main {
          font-size: 16px;
          min-width: 35px;
        }
        padding: 12px 24px;
      }
    }
  }

  @include lxs {
    padding: 12px 0;
    &__controls {
      gap: 12px;

      button {
        img {
          display: none;
        }
      }

      .chose-lang {
        .lang-item.main {
          font-size: 16px;
        }
        padding: 12px;
      }
    }
  }

  @include sm {
    padding: 0 2rem;

    &__content {
      padding: 0;
    }

    &__controls {
      gap: 0.6rem;

      button {
        font-size: 1.8rem;
        padding: 0.8rem 1.2rem;

        img {
          display: none;
        }
      }

      .chose-lang {
        .lang-item.main {
          font-size: 1.6rem;
          min-width: 3.5rem;
          display: flex;
          justify-content: center;
        }
        padding: 0.8rem 1.2rem;

        &:hover .chose-lang__plug {
          top: 3.2rem;
        }
      }
    }

    &__text {
      padding: 3rem 0;
      &__name {
        line-height: 2.8rem;
        font-size: 3.2rem;
        letter-spacing: 0.064rem;
      }

      &__info {
        font-size: 1.6rem;
        letter-spacing: 0.032rem;
      }
    }
  }
}

.russian {
  .header {
    @include sm {
      .custom-button,
      .chose-lang {
        padding: 0.8rem;
      }

      &__text__info {
        font-size: 1.5rem;
      }
    }
  }
}

@import "../mixin";

.chose-lang {
  position: relative;
  background-color: var(--white);
  padding: 16px 12px;
  border-radius: 12px;

  ul {
    display: none;
    min-width: 148px;
  }

  &__plug {
    display: none;
    width: 100%;
  }

  .lang-item {
    display: flex;
    gap: 8px;
    align-items: center;
    min-width: 116px;
    font-size: 18px;

    &.select {
      font-weight: 600;
    }

    &.main {
      font-size: 20px;
      display: grid;
      grid-template-columns: 28px 44px;
      min-width: 72px;
      cursor: pointer;

      &:hover {
        color: var(--gray-black-hover);

        .arrow {
          opacity: 0.6;
        }
      }
    }

    span {
      display: flex;
      align-items: center;
    }

    img {
      width: 28px;
      height: 28px;

      &.arrow {
        width: 24px;
        height: 24px;
      }
    }
  }

  &:hover {
    .chose-lang__plug {
      display: flex;
      position: absolute;
      top: 44px;
      left: 0;
      background-color: var(--white);
      height: 44px;
      z-index: 0;
    }
    .close + .chose-lang__plug {
      display: none;
    }
    ul {
      z-index: 1;
      top: 72px;
      right: 0;
      background-color: var(--white);
      position: absolute;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      list-style: none;
      overflow: hidden;

      &.close {
        display: none;
      }

      li {
        padding: 8px 28px 8px 20px;

        &:hover {
          cursor: pointer;
          background-color: var(--dark-gray);
        }
      }
    }
  }

  @include sm {
    padding: 1.6rem 1.2rem;
    border-radius: 1.2rem;
    min-width: 9.6rem;

    ul {
      min-width: 14.8rem;
    }

    .lang-item {
      gap: 0.8rem;
      min-width: 8rem;
      font-size: 1.4rem;

      &.main {
        font-size: 1.8rem;
        grid-template-columns: 2.8rem 4.4rem;
        min-width: 4rem;
        display: flex;
      }

      img {
        width: 2rem;
        height: 2rem;

        &.arrow {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    &:hover {
      .chose-lang__plug {
        top: 4rem;
        height: 4.4rem;
      }
      ul {
        top: 4.8rem;
        border-radius: 1.2rem;
        li {
          padding: 0.8rem 2.8rem 0.8rem 2rem;
        }
      }
    }
  }
}

@import "../mixin";

.custom-button {
  display: flex;
  gap: 12px;
  background-color: var(--yellow);
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-weight: 500;
  font-size: 24px;
  padding: 12px 64px;
  height: fit-content;
  cursor: pointer;

  &:hover {
    background-color: var(--yellow-hover);
  }

  img {
    max-width: 30px;
    max-height: 30px;
  }

  @include sm {
    gap: 1.2rem;
    border-radius: 0.8rem;
    font-size: 2.4rem;
    padding: 1.2rem 3.6rem;

    img {
      max-width: 3rem;
      max-height: 3rem;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
    &:hover {
      background-color: var(--yellow);
    }
  }

  &.loading {
    position: relative;
    color: var(--yellow) !important;
    opacity: 1;
    background-color: var(--yellow);
    .spinner {
      position: absolute;
      width: 40px;
      height: 40px;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
    }
  }
}

@import "../mixin";

.affiliates__card {
  display: flex;
  flex-direction: column;
  width: 444px;
  background-color: var(--white);
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  &__header {
    background-color: var(--yellow);
    padding: 28px 16px 12px;
    color: var(--white);
    text-align: center;

    h3 {
      font-size: 24px;
      line-height: 18px;
    }

    span {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__items {
    padding: 12px;
    @include columnGap(4px);
  }

  &__item {
    padding: 0 20px;
    font-size: 22px;
    font-weight: 500;
    line-height: 60px;
    color: var(--gray-text);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    &__value {
      color: var(--black);
    }

    &:nth-child(2n + 1) {
      background-color: var(--bg-gray-2);
    }
    &:first-child {
      background-color: var(--red-opacity-1);
      color: var(--red);
      .affiliates__card__item__value {
        color: var(--red);
      }
    }
  }

  @include lg {
    width: 100%;
    max-width: 444px;
    &__item__name,
    &__item__value {
      font-size: 18px;
    }
  }

  @include lxs {
    width: 320px;
    border-radius: 12px 12px;
    overflow: hidden;
    padding: 0;

    &__header {
      width: 100%;
      background-color: var(--yellow);
      padding: 28px 16px 12px;

      h3 {
        font-size: 24px;
        line-height: 18px;
      }

      span {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &__items {
      padding: 12px;
      @include columnGap(4px);
    }

    &__item {
      padding: 0 20px;
      font-size: 16px;
      line-height: 40px;
      border-radius: 12px;
    }
  }

  @include sm {
    width: 28rem;
    border-radius: 1.2rem 1.2rem;
    overflow: hidden;

    &__header {
      background-color: var(--yellow);
      padding: 28px 16px 12px;

      h3 {
        font-size: 2.4rem;
        line-height: 1.8rem;
      }

      span {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }

    &__items {
      padding: 1.2rem;
      @include columnGap(0.4rem);
    }

    &__item {
      padding: 0 2rem;
      font-size: 1.6rem;
      line-height: 4rem;
      border-radius: 1.2rem;
    }
  }
}

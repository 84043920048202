@import "../template.scss";
@import "../mixin";

.home__footer {
  background-color: var(--bg-black);
  color: var(--dark-gray-2);
  padding: 52px 0;

  &__content {
    @extend %home-content;
    font-size: 18px;
    line-height: 24px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 64px;
  }

  &__left {
    @include columnGap(28px);
  }

  &__address {
    display: flex;
    align-items: start;
    gap: 12px;
  }

  &__center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: space-between;

    &__two {
      width: 100%;
      display: flex;
      justify-content: space-between;

      button {
        background-color: transparent;
        color: var(--white);
        font-weight: 500;
        font-size: 16px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    button {
      background-color: transparent;
      color: var(--white);
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
  }

  &__connection {
    padding-top: 24px;
    @include columnGap(8px);

    a {
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--white);
      font-weight: 500;
    }
  }

  @include lg {
    padding: 52px 20px;
    &__content {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    &__center {
      grid-row: 2;
      grid-column: 1/3;
    }

    &__left,
    &__right {
      align-items: center;
    }

    &__actions {
      justify-content: space-around;
    }
  }

  @include lsm {
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;
      padding-bottom: 50px;
    }

    &__actions {
      // display: flex;
      // flex-direction: column;
      gap: 20px;
    }

    &__copyright {
      position: absolute;
      bottom: 0;
      left: calc(50% - 133px);
    }

    &__address {
      img {
        display: none;
      }
    }
  }

  @include lxs {
    padding: 50px;
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 40px;
      font-size: 18px;
      line-height: 20px;
      padding-bottom: 60px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    &__left {
      align-items: center;
    }

    &__address {
      address {
        width: 190px;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 20px;

      button {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__copyright {
      font-size: 17px;
      position: absolute;
      bottom: 0;
    }

    &__right {
      align-items: center;
      a,
      span {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &__connection {
      gap: 16px;
    }
  }

  @include sm {
    padding: 5rem 2rem;
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 4rem;
      font-size: 1.8rem;
      line-height: 2rem;
      padding: 0;
      padding-bottom: 6rem;

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &__left {
      align-items: center;
    }

    &__address {
      address {
        width: 19rem;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      button {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }

    &__copyright {
      font-size: 1.7rem;
      position: absolute;
      bottom: 0;
    }

    &__right {
      align-items: center;
      a,
      span {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }
    &__connection {
      gap: 1.6rem;
    }
  }
}

.russian {
  .home__footer {
    &__content {
      padding: 0;
      padding-bottom: 60px;
    }

    &__actions {
      gap: 16px;
    }
    @include sm {
      &__content {
        padding-bottom: 6rem;
      }

      &__actions {
        gap: 1.2rem;
      }
    }
  }
}

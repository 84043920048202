@import "../template.scss";
@import "../mixin";
%opacity-blue-box {
  background-color: var(--opacity-box-blue);
  padding: 32px;
  border-radius: 12px;
}
.home__welcome {
  width: 100%;

  background-color: var(--red-2);
  &__content {
    @extend %home-content;
    background: url("../../images/home/bdsm.png") no-repeat;
    background-position-y: -44px;
    display: flex;
    justify-content: space-between;
    gap: 216px;
    padding: 92px 0;
    padding-bottom: 72px;
    color: var(--white);
  }

  &__left {
    @include columnGap(64px);
    padding-top: 16px;

    &__webmaster {
      &__title,
      p {
        font-size: 28px;
        line-height: 34px;
        font-weight: 700;
        max-width: 476px;
      }

      &__title {
        font-size: 52px;
        line-height: 60px;
        text-transform: uppercase;
      }
    }

    &__text-camhafs {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      max-width: 476px;

      a {
        color: var(--yellow);
      }
    }

    &__money {
      @extend %opacity-blue-box;
      width: 432px;
      color: var(--white);
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: 28px;
        line-height: 34px;
        font-weight: 700;
        text-transform: uppercase;
      }

      button {
        margin-top: 20px;
        // width: 320px;
      }

      & > span {
        margin-top: 16px;
      }

      &__politic {
        color: var(--pink-white-9);
        button {
          width: auto;
          background-color: transparent;
          display: inline;
          color: var(--yellow);
          margin: 0;
        }
      }
    }
  }

  &__right {
    @include columnGap(24px);
    @extend %opacity-blue-box;
    width: 430px;

    &__top {
      padding-bottom: 12px;
      border-bottom: 2px solid var(--white-opacity-3);

      h2 {
        line-height: 40px;
        font-size: 40px;
      }

      span {
        font-size: 20px;
        line-height: 34px;
        font-weight: 500;
      }
    }

    &__percents {
      padding-right: 16px;
      @include columnGap(32px);

      h2 {
        font-size: 40px;
        line-height: 80%;
        color: var(--yellow);
      }

      h4 {
        font-size: 28px;
        line-height: 32px;
      }

      span {
        margin-top: 12px;
        line-height: 20px;
        font-size: 18px;
        font-weight: 500;
        color: var(--pink-white-9);
      }
    }
  }

  @include lg {
    $titleSize: 24px;
    $titleBigSize: 30px;

    &__content {
      gap: 40px;

      background-size: auto;
      background-position: 50%;
      padding: 100px 16px;
    }

    &__left {
      gap: 40px;
      max-width: 42%;
      p {
        font-size: 20px;
        line-height: 24px;
      }
      &__webmaster {
        &__title {
          font-size: $titleBigSize;
          line-height: 40px;
        }
      }

      &__money {
        padding: 20px;
        width: 100%;
        h3 {
          font-size: 20px;
          line-height: 24px;
        }

        .custom-button {
          font-size: $titleSize;
          width: 100%;
          padding: 12px;
        }
      }
    }

    &__right {
      padding: 20px;
      max-width: 42%;
      width: 100%;
      &__top {
        h2 {
          font-size: $titleBigSize;
          line-height: 40px;
        }
      }
      &__percents {
        gap: 20px;
        padding-right: 0;
        h2 {
          font-size: 28px;
        }

        h4 {
          font-size: $titleSize;
          line-height: 28px;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }

  @include lsm {
    &__content {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      background-position-y: 104.5%;
      padding-bottom: 60%;
      background-size: contain;
    }

    &__left {
      align-items: center;
    }
  }

  @include lxs {
    &__content {
      background-position-y: 102.5%;
    }
    &__left {
      @include columnGap(36px);
      width: 100%;

      &__webmaster {
        &__title,
        p {
          font-size: 32px;
          line-height: 36px;
        }

        &__title {
          font-size: 48px;
          line-height: 52px;
        }
      }

      &__text-camhafs {
        font-size: 20px;
        line-height: 24px;
        br {
          display: none;
        }
      }

      &__money {
        width: 100%;

        h3 {
          font-size: 28px;
          line-height: 34px;
          text-align: center;
        }

        button {
          margin-top: 20px;
          width: 100%;
          font-size: 24px;
        }

        & > span {
          margin-top: 16px;
        }

        &__politic {
          font-size: 16px;
          line-height: 24px;

          button {
            width: auto;
            line-height: 24px;
          }
        }
      }
    }

    &__right {
      @include columnGap(20px);
      width: 100%;

      &__top {
        padding-bottom: 16px;
        border-bottom: 2px solid var(--white-opacity-3);

        h2 {
          line-height: 34px;
          font-size: 28px;
        }

        span {
          font-size: 18px;
          line-height: 34px;
        }
      }

      &__percents {
        padding-right: 16px;
        @include columnGap(32px);

        h2 {
          font-size: 32px;
        }

        h4 {
          font-size: 24px;
          line-height: 32px;
        }

        span {
          margin-top: 8px;
          line-height: 20px;
          font-size: 18px;
        }
      }
    }
  }

  @include sm {
    %opacity-blue-box {
      padding: 3.2rem 1.6rem;
      border-radius: 1.2rem;
      width: 100%;
    }

    &__content {
      @extend %home-content;
      background-position-y: 101.1%;
      gap: 4rem;
      padding: 4rem 2rem;
      padding-bottom: 18rem;

      * {
        max-width: 100%;
      }
    }

    &__left {
      @include columnGap(3.6rem);
      width: 100%;

      &__webmaster {
        &__title,
        p {
          font-size: 2rem;
          line-height: 2.4rem;
        }

        &__title {
          font-size: 3.12rem;
          line-height: 3.6rem;
        }
      }

      &__text-camhafs {
        font-size: 2rem;
        line-height: 2.4rem;
      }

      &__money {
        @extend %opacity-blue-box;

        h3 {
          font-size: 2.8rem;
          line-height: 3.4rem;
          text-align: center;
        }

        button {
          margin-top: 2rem;
          width: 100%;
          font-size: 2.4rem;
        }

        & > span {
          margin-top: 1.6rem;
        }

        &__politic {
          font-size: 1.6rem;
          line-height: 2.4rem;

          button {
            width: auto;
            line-height: 2.4rem;
            margin: 0;
          }
        }
      }
    }

    &__right {
      @include columnGap(2rem);
      @extend %opacity-blue-box;
      width: 100%;

      &__top {
        padding-bottom: 1.6rem;
        border-bottom: 0.2rem solid var(--white-opacity-3);

        h2 {
          line-height: 3.4rem;
          font-size: 2.8rem;
        }

        span {
          font-size: 1.8rem;
          line-height: 3.4rem;
        }
      }

      &__percents {
        padding-right: 1.6rem;
        @include columnGap(3.2rem);

        h2 {
          font-size: 3.2rem;
        }

        h4 {
          font-size: 2.4rem;
          line-height: 3.2rem;
        }

        span {
          margin-top: 0.8rem;
          line-height: 2rem;
          font-size: 1.8rem;
        }
      }
    }

    @media (min-device-width: 550px) {
      .home__welcome__left__webmaster__title {
        font-size: 2.6rem;
      }
    }
  }
}

.english {
  .home__welcome__left__webmaster__title {
    line-height: 42px;
  }
}

.russian {
  .home__welcome {
    &__left {
      gap: 40px;

      &__webmaster__title {
        font-size: 40px;
        line-height: 44px;
      }
    }

    &__content {
      padding-top: 72px;
      padding-bottom: 54px;
    }

    &__left__webmaster p {
      font-size: 24px;
      line-height: 32px;
    }

    &__right__percents {
      gap: 20px;
    }

    @include lg {
      &__left {
        gap: 40px;

        &__webmaster {
          &__title {
            font-size: 28px;
            line-height: 34px;
          }

          p {
            font-size: 18px;
            line-height: 22px;
          }
        }

        .custom-button {
          font-size: 20px;
        }
      }

      &__content {
        padding-top: 72px;
        padding-bottom: 50px;
      }

      &__right__percents {
        gap: 20px;
      }
    }

    @include lsm {
      &__left__money__politic {
        button {
          font-size: 15px;
          margin: 0;
        }
      }

      &__content {
        padding-bottom: 60%;
      }
    }

    @include sm {
      &__content {
        padding-top: 4.4rem;
        background-position-y: 101%;
        padding-bottom: 20rem;
      }

      &__left {
        &__webmaster {
          p {
            font-size: 1.8rem;
          }
          &__title {
            font-size: 3.6rem;
            line-height: 4rem;
          }
        }

        &__text-camhafs {
          font-size: 1.8rem;
        }

        &__money {
          .custom-button {
            padding: 1.2rem;
            font-size: 1.8rem;
          }

          &__politic {
            font-size: 1.2rem;
            button {
              font-size: 1.6rem;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.espanol {
  .home__welcome {
    &__right__top h2 {
      font-size: 32px;
    }

    &__left {
      &__webmaster__title {
        font-size: 40px;
      }

      &__text-camhafs {
        font-size: 16px;
      }
    }
  }
}

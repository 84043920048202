@import "../template.scss";
@import "../mixin";

.home__advantages {
  background-color: var(--bg-black);
  padding: 108px 0;

  &__content {
    @extend %home-content;
    @include columnGap(108px);
    color: var(--white);
  }

  &__info {
    display: flex;
    gap: 184px;
  }

  &__list {
    &__main > li {
      width: max-content;
      & > span {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 24px;
        line-height: 52px;
        font-weight: 700;
        text-transform: uppercase;

        img {
          width: 32px;
        }
      }
    }

    &__dop {
      padding-top: 12px;
      padding-left: 44px;
      @include columnGap(36px);
      // width: 444px;

      & > li {
        width: 432px;
        & > span {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          img {
            width: 24px;
          }
        }
      }
    }
  }

  &__payment {
    border: 4px solid var(--yellow);
    border-radius: 12px;
    padding: 24px;
    text-align: center;

    h2 {
      position: relative;
      font-size: 26px;
      line-height: 34px;
      padding: 4px 16px 20px;
      border-bottom: 2px solid var(--yellow);
      &::after {
        position: absolute;
        background: url("../../images/icon/polygon.svg") no-repeat;
        background-size: 12px;
        width: 12px;
        height: 8px;
        bottom: -8px;
        left: calc(50% - 6px);
        content: "";
      }
    }

    &__currencies {
      margin-top: 32px;
      @include columnGap(24px);

      h3 {
        font-size: 24px;
        line-height: 36px;
        color: var(--yellow);
      }

      h4 {
        font-size: 20px;
        line-height: 36px;
        color: var(--dark-gray-2);
        padding: 0 12px;
      }
    }
  }

  &__ask {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: var(--dark-gray-2);
    &__actions {
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 20px;

      .action {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--white);
        font-weight: 500;
      }
    }
    &__title {
      font-size: 24px;
    }
  }

  @include lg {
    padding: 100px 20px;

    &__content {
      gap: 88px;
    }

    &__info {
      gap: 32px;
      justify-content: space-between;
    }

    &__list {
      &__main > li > span {
        font-size: 17px;
        line-height: 44px;
      }
      &__dop {
        width: 55vw;
        max-width: 550px;
        font-size: 14px;
        padding-left: 28px;

        & > li {
          width: 100%;
        }
      }
    }

    &__payment {
      padding: 16px;
      h2 {
        padding: 4px 0 20px;
        font-size: 20px;
        line-height: 28px;
      }

      h4 {
        font-size: 16px;
        padding: 0;
        line-height: 24px;
      }
    }
  }

  @include lsm {
    &__info {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    &__list {
      &__main {
        & > li {
          & > span {
            font-size: 20px;
          }
        }
      }
    }

    &__ask {
      flex-direction: column;
    }
  }
  @include lxs {
    padding: 44px 0;

    &__content {
      @include columnGap(40px);
      padding: 0 20px;
    }

    &__info {
      gap: 40px;
    }

    &__list {
      &__main {
        @include columnGap(20px);
        & > li {
          width: auto;
          & > span {
            gap: 12px;
            font-size: 20px;
            line-height: 20px;
            align-items: start;

            img {
              width: 32px;
              height: 32px;
            }
          }
        }
      }

      &__dop {
        padding-top: 32px;
        padding-left: 0;
        @include columnGap(36px);

        & > li {
          width: auto;
          font-size: 18px;
          line-height: 24px;
          & > span {
            gap: 8px;

            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    &__payment {
      border: 4px solid var(--yellow);
      border-radius: 12px;
      padding: 24px;

      h2 {
        font-size: 26px;
        line-height: 30px;
        padding: 4px 0 2px;
        border-bottom: 2px solid var(--yellow);
        &::after {
          background-size: 12px;
          width: 12px;
          height: 8px;
          bottom: -8px;
        }
      }

      &__currencies {
        margin-top: 40px;
        @include columnGap(24px);

        h3 {
          font-size: 24px;
          line-height: 36px;
        }

        h4 {
          font-size: 20px;
          line-height: 28px;
          padding: 0 12px;
        }
      }

      img {
        width: 40px;
      }
    }

    &__ask {
      flex-direction: column;
      gap: 28px;
      color: var(--dark-gray-2);
      &__actions {
        flex-direction: column;
        gap: 0;
        font-size: 20px;

        .action {
          gap: 8px;
        }
      }
      &__title {
        font-size: 24px;
      }
    }
  }
  @include sm {
    padding: 4.4rem 0;

    &__content {
      @include columnGap(4rem);
      padding: 0 2rem;
    }

    &__info {
      gap: 4rem;
    }

    &__list {
      &__main {
        @include columnGap(2rem);
        & > li {
          width: auto;
          & > span {
            gap: 1.2rem;
            font-size: 2rem;
            line-height: 2.4rem;
            align-items: start;

            img {
              width: 3.2rem;
              height: 3.2rem;
            }
          }
        }
      }

      &__dop {
        padding-top: 3.2rem;
        padding-left: 0;
        @include columnGap(3.6rem);

        & > li {
          width: auto;
          font-size: 1.8rem;
          line-height: 2.4rem;
          & > span {
            gap: 0.8rem;

            img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }
        }
      }
    }

    &__payment {
      border: 0.4rem solid var(--yellow);
      border-radius: 1.2rem;
      padding: 2.4rem;

      h2 {
        font-size: 2.6rem;
        line-height: 3rem;
        padding: 0.4rem 0 2rem;
        border-bottom: 0.2rem solid var(--yellow);
        &::after {
          background-size: 1.2rem;
          width: 1.2rem;
          height: 0.8rem;
          bottom: -0.8rem;
        }
      }

      &__currencies {
        margin-top: 4rem;
        @include columnGap(2.4rem);

        h3 {
          font-size: 2.4rem;
          line-height: 3.6rem;
        }

        h4 {
          font-size: 2rem;
          line-height: 2.8rem;
          padding: 0 1.2rem;
        }
      }

      img {
        width: 4rem;
      }
    }

    &__ask {
      flex-direction: column;
      gap: 2.8rem;
      color: var(--dark-gray-2);
      &__actions {
        flex-direction: column;
        gap: 0;
        font-size: 2rem;

        .action {
          gap: 0.8rem;
        }
      }
      &__title {
        font-size: 2.4rem;
      }
    }
  }
}

.russian {
  .home__advantages {
    &__payment {
      padding: 20px 16px;

      h4 {
        padding: 0;
      }
    }
    @include sm {
      &__payment {
        h4 {
          font-size: 1.8rem;
        }
      }

      &__list__main > li > span {
        font-size: 1.8rem;
        align-items: center;
      }
    }
  }
}

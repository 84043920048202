@mixin lg {
  @media (max-width: 1150px) and (min-width: 740px) {
    @content;
  }
}

@mixin lsm {
  @media (max-width: 739px) {
    @content;
  }
}

@mixin lxs {
  @media (max-width: 576px) {
    &__content {
      padding-left: 12px;
      padding-right: 12px;
    }
    @content;
  }
}

@mixin sm {
  @media (max-device-width: 739px) and (max-width: 739px) and (orientation: portrait) {
    @content;
  }
}

@mixin columnGap($gap: 12px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin lineAsideText($textWidth, $padding, $widthBlock) {
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: calc($widthBlock / 2 - $textWidth / 2 - $padding);
    top: calc(36px / 2 - 1px / 2); // line-height / 2 - heightLine / 2
    right: $textWidth + $padding;
    background: var(--dark-gray-2);
  }
  &::before {
    content: "";
    position: absolute;
    height: 1px;
    top: calc(36px / 2 - 1px / 2); // line-height / 2 - heightLine / 2
    width: calc($widthBlock / 2 - $textWidth / 2 - $padding);
    left: $textWidth + $padding;
    background: var(--dark-gray-2);
  }
}

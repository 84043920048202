:root {
  // color
  --white: #fff;
  --black: #000;
  --gray-color: #f4f4f4;
  --dark-gray: #e2e2e2;
  --dark-gray-2: #aaa;
  --white-opacity-3: rgba(255, 255, 255, 0.3);
  --red: #cf283a;
  --red-2: #b21624;
  --yellow: #ffa723;
  --pink: #feb6be;
  --pink-white-9: #e9d2d8;
  --red-opacity-1: rgba(207, 40, 58, 0.1);

  //box
  --opacity-box-blue: rgba(39, 44, 61, 0.6);

  // bg
  --bg-black: #222328;
  --bg-black-2: #131313;
  --bg-gray: #efefef;
  --bg-gray-2: #f6f6f6;

  //hover
  --yellow-hover: #ffbb54;
  --red-hover: #dd3e4e;
  --gray-black-hover: #7b7b7b;

  // text
  --gray-text: #7b7b7b;

  // dimensions
  --home-width-content: 1130px;
}

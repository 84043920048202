.text-page {
  position: relative;
  color: var(--label-text-6);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 28px 60px;

  .modal__close {
    top: 32px;
    right: 32px;
    scale: 1.5;
  }

  h1,
  h2,
  h3 {
    color: var(--black);
  }

  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 18px;
  }

  .btn-as-link {
    background-color: transparent;
    border: none;
    display: inline;
  }

  a,
  .btn-as-link {
    color: var(--label-text-4);
    text-decoration: underline;
    &:hover {
      color: var(--black);
      text-decoration: none;
    }
  }

  p,
  i,
  pre,
  li {
    font-size: 14px;
    line-height: 18px;
    text-wrap: balance;
  }

  b {
    font-weight: 900;
    color: var(--black);
  }

  b.title {
    display: block;
    font-size: 18px;
    line-height: 60px;
  }

  ul.style-circle {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    li {
      margin-left: 20px;
    }
  }

  table {
    border-collapse: collapse;
  }
  table,
  th,
  td {
    border: 1px solid;
    font-size: 16px;
    padding: 4px 8px;
  }

  // @include smOrB2k {
  // 	gap: 2rem;
  // 	padding: 3.2rem 2.8rem 6rem;

  // 	h1 {
  // 		font-size: 2rem;
  // 	}
  // 	h2 {
  // 		font-size: 1.8rem;
  // 	}
  // 	h3 {
  // 		font-size: 1.6rem;
  // 	}

  // 	p,
  // 	i,
  // 	pre,
  // 	li {
  // 		font-size: 1.4rem;
  // 		line-height: 140%;
  // 	}

  // 	b.title {
  // 		font-size: 1.8rem;
  // 		line-height: 6rem;
  // 	}

  // 	ul.style-circle {
  // 		margin-top: 1.2rem;
  // 		gap: 0.4rem;
  // 		li {
  // 			margin-left: 2rem;
  // 		}
  // 	}
  // }
}

@import "../template.scss";
@import "../mixin";
@import url("../home/affiliates-by-card.scss");

@import url("../home/welcome.scss");
@import url("../home/advantages.scss");
@import url("../home/affiliates.scss");
@import url("../home/footer.scss");

.home__actions {
  background-color: var(--bg-black-2);
  color: var(--white);

  &__content {
    @extend %home-content;
    display: flex;
    justify-content: space-around;
    padding: 24px;
  }

  &__cams,
  &__model {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    line-height: 34px;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
  }
}

.approve-modal {
  outline: none;
  max-width: 1040px;
  position: absolute;
  inset: 40px;
  left: calc(50vw - 520px);
  padding: 60px;
  padding-right: 36px;
  border-radius: 8px;
  background-color: var(--white);
  max-height: 90vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 40px;
    line-height: 36px;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;
  }

  &.service {
    .approve-modal__title {
      @include lineAsideText(388px, 40px, 920px);
    }
  }
  &.privacy {
    .approve-modal__title {
      @include lineAsideText(350px, 40px, 920px);
    }
  }
  &.cookies {
    .approve-modal__title {
      @include lineAsideText(340px, 40px, 920px);
    }
  }

  &__text {
    margin-top: 52px;
    max-height: calc(100% - 52px - 60px);
    overflow: auto;
    padding-right: 24px;
  }
}

@include lg {
  .approve-modal {
    max-width: 90vw;
    left: 5vw;
    padding: 20px;

    &__title {
      &::after,
      &::before {
        display: none;
      }
    }
  }

  .home__actions {
    &__content {
      gap: 20px;
    }

    &__cams,
    &__model {
      &__text {
        line-height: 24px;
      }
    }
  }
}

@include lsm {
  .home__actions__content {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .approve-modal {
    width: 95vw;
    left: 2.5vw;
    &__title {
      margin-top: 50px;
      font-size: 36px;
    }
  }
}

@include sm {
  .home__actions {
    &__content {
      flex-direction: column;
      gap: 2rem;
      align-items: center;

      img {
        width: 2.4rem;
      }
    }

    &__cams,
    &__model {
      gap: 1.2rem;
      font-size: 1.2rem;
      line-height: 3.4rem;

      &__text {
        line-height: 150%;
      }
    }
  }

  .approve-modal {
    padding: 1rem 2rem;
    &__text {
      font-size: 1.2rem;
    }

    &__title {
      &::before,
      &::after {
        display: none;
      }
    }

    &__text {
      margin-top: 3rem;
    }
  }
}

.russian.approve-modal {
  &.service {
    .approve-modal__title {
      @include lineAsideText(575px, 40px, 920px);
    }
  }
  &.privacy {
    .approve-modal__title {
      @include lineAsideText(780px, 40px, 920px);
    }
  }
  &.cookies {
    .approve-modal__title {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  @include sm {
    .approve-modal {
      &__title {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
}

@import "../template.scss";
@import "../mixin";

.home__affiliates {
  background-color: var(--bg-gray);
  padding: 108px 0;
  &__content {
    @extend %home-content;
    @include columnGap(52px);
    align-items: center;
  }

  &__card-by__day {
    .affiliates__card__header {
      background-color: var(--red);

      span {
        color: var(--pink);
      }
    }
  }

  &__title {
    font-size: 40px;
    line-height: 36px;
    @include lineAsideText(308px, 40px, var(--home-width-content));
  }

  &__cards {
    display: flex;
    gap: 88px;
  }

  @include lg {
    &__cards {
      gap: 40px;
      width: 100%;
      padding: 20px;
      justify-content: center;
    }
    &__title {
      &::after,
      &::before {
        display: none;
      }
    }
  }

  @include lsm {
    &__cards {
      flex-direction: column;
      gap: 40px;
    }

    &__title {
      &::after,
      &::before {
        display: none;
      }
    }
  }

  @include lxs {
    padding: 60px 0;
    &__content {
      @include columnGap(40px);
    }

    &__title {
      font-size: 28px;
      line-height: 36px;
      &::after,
      &::before {
        display: none;
      }
    }

    &__cards {
      gap: 40px;
    }
  }

  @include sm {
    padding: 6rem 0;
    &__content {
      @include columnGap(4rem);
    }

    &__title {
      font-size: 2.8rem;
      line-height: 3.6rem;
      &::after,
      &::before {
        display: none;
      }
    }

    &__cards {
      gap: 4rem;
    }
  }
}
